<!-- 关于软件 -->
<template>
    <div v-if="tshow" class="about_soft">
        <h1 class="title"> <i class="el-icon-close" @click="close()"></i> </h1>
        <div class="content">
            <h1>{{info.sourceName}}</h1>
            <div class="description" v-html="info.description"></div>
            <!-- <div class="sltit"> 
                <h2>课程</h2>    
                <p>
                    课程是指学校学生所应学习的学科总和及其进程与安排。课程是对教育的目标、教学内容、教学活动方式的规划和设计，是教学计划、教学大纲等诸多方面实施过程的总和。广义的课程是指学校为实现培养目标而选择的教育内容及其进程的总和，它包括学校老师所教授的各门学科和有目的、有计划的教育活动。狭义的课程是指某一门学科。
                </p>
            </div>
            <div class="sltit"> 
                <h2>特点</h2>    
                <p>
                    当课程被认识为知识并付诸实践时，一般特点在于： <br>
                    A课程体系是以科学逻辑组织的；<br>
                    B课程是社会选择和社会意志的体现；<br>
                    C课程是既定的、先验的、静态的；<br>
                    D课程是外在于学习者的，并且是凌驾于学习者之上的。
                </p>
            </div>
            <div class="sltit"> 
                <h2>活动</h2>    
                <p>
                    课程，包括文化课程、活动课程、实践课程、隐性课程。文化课程包括国家课程、地方课程、校本课程，活动课程包括阳光体育、大型活动、兴趣小组生会、团委等学生团体组织的自主活动、综合实践活动，隐性课程包括除了上述几类课程，一切有利于学生发展的资源、环境、学校的文化建设、家校社会一体化等。
                </p>
            </div> -->
        </div>
    </div>
</template>

<script>
    export default {
        props:['show','info'],
        data(){
            return{
                tshow:false,
            }
        },
        watch:{
            show:function(v){
                this.tshow = v;
                console.log(this.info,'456');
                
            }
        },
        methods:{
            close(){
                this.$emit('update:show',false);
            }
        },
    }
</script>

<style scoped>
.description{
    font-size: 20px;
}

</style>